import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import axios from 'axios';
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import "../css/Registration.css";
import { Navpanel } from '../components/Navbar';


function Registration() {
    return(
    <>
    <Navpanel/>
    <div className='marco'>
    <Container>
            <Row className="justify-content-md-center">
                {/* Left-Side Section */}
                <Col md={4}>
                    <div class="sticky-top" style={{top: "90px", marginTop: "25px", marginBottom: "25px", padding: "20px", backdropFilter: "blur(3px)", boxSizing: "border-box", boxShadow: "0 15px 25px rgba(0, 0, 0, .6)", borderRadius: "10px" }}>
                        <h4>Formulario de Inscripción</h4>
                        <Nav.Link className='Links_Registration' href="#datos_personales">Datos Personales</Nav.Link>
                        <Nav.Link className='Links_Registration' href="#datos_organizacion">Datos de tu Organización</Nav.Link>
                        <Nav.Link className='Links_Registration' href="#preguntas_sociales">Preguntas Sociales y Medioambientales</Nav.Link>
                        <Nav.Link className='Links_Registration' href="#terminos_condiciones">Terminos y Condiciones</Nav.Link>
                    </div>
                </Col>
                <Col  md={8} style={{marginTop:"25px", marginBottom:"25px", backdropFilter: "blur(3px)",boxSizing:"border-box",boxShadow: "0 15px 25px rgba(0, 0, 0, .6)",borderRadius: "10px"}}>
                <Form className='formulario'>
                    <center>
                    <h6 id='datos_personales' style={{ fontWeight:"bold", marginTop:"30px"}} className="divider_title">Datos Personales</h6>
                    <h6>____________________________________________________________________________________</h6>
                    </center>
                    <br></br>

                    <Row className="mb-3">
                    <Form.Group as={Col} >
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control placeholder="Ingrese su nombre" id="name_company" name="name_company"/>
                    </Form.Group>

                    <Form.Group as={Col} >
                        <Form.Label>Primer Apellido</Form.Label>
                        <Form.Control placeholder="Ingrese su primer apellido" id="name_company" name="name_company"/>
                    </Form.Group>

                    <Form.Group as={Col} >
                        <Form.Label>Segundo Apellido</Form.Label>
                        <Form.Control placeholder="Ingrese su segundo apellido" id="name_company" name="name_company"/>
                    </Form.Group>
                    </Row>

                    <Row className="mb-3">
                    <Form.Group as={Col} >
                        <Form.Label>En cuanto a género, ¿Cómo te identificas?</Form.Label>
                        <Form.Control placeholder="Ingrese su género"  id="area_company" name="area_company"/>
                    </Form.Group>

                    <Form.Group as={Col} >
                        <Form.Label>Email</Form.Label>
                        <Form.Control placeholder="Ingrese su correo electronico" id="country_company" name="country_company"/>
                    </Form.Group>
                    </Row>

                    <Form.Group className="mb-3" >
                    <Form.Label>RUT</Form.Label>
                    <Form.Control placeholder="Ingrese su RUT en formato 11.111.111-1"  id="address_company" name="address_company"/>
                    </Form.Group>

                    <Form.Group className="mb-3" >
                    <Form.Label>Celular</Form.Label>
                    <Form.Control placeholder="Ingrese su cecular en formato +56 9 9999 9999"  id="web_company" name="web_company"/>
                    </Form.Group>

                    <Form.Group className="mb-3" >
                    <Form.Label>¿Perteneces a alguna de estas comunidades o gremios empresariales?</Form.Label>
                    <Form.Control placeholder="Ingrese su comunidad"  id="web_company" name="web_company"/>
                    </Form.Group>

                    <center>
                    <h6 id='datos_organizacion' style={{ fontWeight:"bold"}} className="divider_title">Datos de tu Organización o Emprendimiento</h6>
                    <h6>____________________________________________________________________________________</h6>
                    </center>
                    <br></br>

                    <Row className="mb-3">
                    <Form.Group className="mb-3" >
                        <Form.Label>Nombre de de tu Organización o Emprendimiento</Form.Label>
                        <Form.Control placeholder=""  id="name_supervisor" name="name_supervisor"/>
                    </Form.Group>

                    <Form.Group className="mb-3" >
                        <Form.Label>Tipo de Organización o Emprendimiento</Form.Label>
                        <Form.Control placeholder=""  id="first_surname_supervisor" name="first_surname_supervisor"/>
                    </Form.Group>
                    </Row>

                    <Row className="mb-3">
                    <Form.Group className="mb-3">
                        <Form.Label>Tamaño de su Organización o Emprendimiento</Form.Label>
                        <Form.Control placeholder=""  id="second_surname_supervisor" name="second_surname_supervisor"/>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>En que area trabajas o qué temas ves en tu organización o emprendimiento</Form.Label>
                        <Form.Control placeholder=""  id="position_supervisor" name="position_supervisor"/>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Industria o sector de tu organización o emprendimiento</Form.Label>
                        <Form.Control placeholder=""  id="position_supervisor" name="position_supervisor"/>
                    </Form.Group>

                    <Form.Group className="mb-3" >
                        <Form.Label>Describe tu organización o emprendimiento en pocas palabras ¿Qué haces?</Form.Label>
                        <Form.Control as="textarea" rows={3}  id="functions" name="functions"/>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>¿Tu organización o emprendimiento esta formalizado legalmente?</Form.Label>
                        <Form.Control placeholder=""  id="position_supervisor" name="position_supervisor"/>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Indica el año de inicio de operaciones de la organización o emprendimiento</Form.Label>
                        <Form.Control placeholder=""  id="position_supervisor" name="position_supervisor"/>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>¿En qué región se centra la operación de tu organización o emprendimiento?</Form.Label>
                        <Form.Control placeholder=""  id="position_supervisor" name="position_supervisor"/>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>¿En qué regiones vende tu organización o emprendimiento?</Form.Label>
                        <Form.Control placeholder=""  id="position_supervisor" name="position_supervisor"/>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>¿Qué sectores/industrias son de tu interés para reunirte en la ronda de negocios? (Deberias seleccionar la(s) industria(s) con la que te interesaría conectar)</Form.Label>
                        <Form.Control placeholder=""  id="position_supervisor" name="position_supervisor"/>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>¿Que necesidad(es) tiene tu organización o emprendimiento para esta ronda?</Form.Label>
                        <Form.Control placeholder=""  id="position_supervisor" name="position_supervisor"/>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Indica el sitio web o redes sociales de tu organización o emprendimiento</Form.Label>
                        <Form.Control placeholder=""  id="position_supervisor" name="position_supervisor"/>
                    </Form.Group>

                    </Row>

                    <center>
                    <h6 id='preguntas_sociales' style={{ fontWeight:"bold"}} className="divider_title">Preguntas Sociales y Medioambientales</h6>
                    <h6>____________________________________________________________________________________</h6>
                    </center>
                    <br></br>

                    <Row className="mb-3">
                    <Form.Group className="mb-3">
                        <Form.Label>¿Tu organización o emprendimiento tiene alguno de estos sellos/certificaciones?</Form.Label>
                        <Form.Control placeholder=""  id="position_supervisor" name="position_supervisor"/>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>¿Te gustaría saber qué es el movimiento de Empresas B y cómo realizar una medición de impacto para tu organización o emprendimiento?</Form.Label>
                        <Form.Control placeholder=""  id="position_supervisor" name="position_supervisor"/>
                    </Form.Group>
                    </Row>


                    <center>
                    <h6 id='terminos_condiciones' style={{ fontWeight:"bold"}} className="divider_title">Terminos y Condiciones</h6>
                    <h6>____________________________________________________________________________________</h6>
                    </center>
                    <br></br>

                    <Row className="mb-3">
                    <Form.Group className="mb-3">
                        <Form.Label>Acepto los Términos y Condiciones y Políticas de Privacidad de Negocios con Impacto.</Form.Label>
                        <Form.Control placeholder=""  id="position_supervisor" name="position_supervisor"/>
                    </Form.Group>
                    </Row>
            
                    <div style={{ display: "flex", justifyContent: "center", marginBottom:"30px" }}>
                    <Button variant="dark" type="submit">
                        Inscribir
                    </Button>
                    </div>
                    
                </Form>
                </Col>
            </Row>
            </Container>
            </div>
    </>
    );
};

export {Registration};