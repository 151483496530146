import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from './subpages/Home';
import { Registration } from './subpages/Registration';
import { Login } from './subpages/Login';

function App() {
  return (
    <Router>
    <Routes>
      <Route path="/" element={<Home/>}></Route>
      <Route path="/Inscripcion" element={<Registration/>}></Route>
      <Route path="/Iniciar_Sesion" element={<Login/>}></Route>
    </Routes>
    </Router>
  );
}

export default App;
