import React, { useEffect, useState } from "react";
import "../css/Login.css";
import logo from '../images/negocio_con_impacto.png';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

import { useSelector, useDispatch } from "react-redux";

function Login() {
    return(
            <section className="Login">
            <div className="login-box">
            <img className="Image_login" src={logo} />
            <form>
                <div className="user-box">
                <input required="true" name="username" type="email"></input>
                <label>Usuario</label>
                </div>
                <div className="user-box">
                <input required="true" name="password" type="password"></input>
                <label>Contraseña</label>
                </div>
                <button type="submit" className="login-button">Iniciar sesión</button>
            </form>
            </div>
    
            {/* Success Modal */}
            <Modal >
            <Modal.Header closeButton>
                <Modal.Title>Iniciar sesión</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Has iniciado sesión correctamente!
            </Modal.Body>
            <Modal.Footer>
                <Button variant="dark" >
                Continuar
                </Button>
            </Modal.Footer>
            </Modal>
    
            {/* Error Modal */}
            <Modal >
            <Modal.Header closeButton>
                <Modal.Title>Error</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Credenciales incorrectas. Por favor, inténtalo de nuevo.
            </Modal.Body>
            <Modal.Footer>
                <Button variant="dark" >
                Cerrar
                </Button>
            </Modal.Footer>
            </Modal>
        </section>
    );
    
}

export {Login}