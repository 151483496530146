import React from 'react';
import "../css/Home.css";
import { Navpanel } from '../components/Navbar';
import emprediem from '../images/emprediem.png';
import sistema_b from '../images/sistema_b.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/esm/Container';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

function Home() {
    return(
        <>
            <Navpanel/>
            <div className="border-top border-white"></div>
            <div className="home">
                <div className="home-pic">
                    <Row className="justify-content-center align-items-center">
                        <Col xs="12" md="6">
                            <p className="home-statement">Redefiniendo la manera de hacer negocios</p>
                        </Col>
                        <Col xs="12" md="6">
                            <div className="text-center">
                                <p className="home-collaboration">Un proyecto colaborativo de:</p>
                                <img src={sistema_b} alt='sistema_b' className="img-fluid" />
                                <br />
                                <img src={emprediem} alt='emprediem' className="img-fluid" />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            
            <div id='quienes_somos'>
                <Container fluid="md">
                    <Row>
                        <Row className="text-center">
                            <h2 className="mt-4 mb-4">¿Quienes Somos?</h2>
                        </Row>
                    </Row>
                </Container>
            </div>

            <div className='custom-bg-color'>
                <Row>
                    <p className="lead text-center text-justify">
                    El puente que permite encuentros entre potenciales clientes, proveedores y aliados estratégicos, con el objetivo de generar oportunidades y favorecer el ecosistema del triple impacto, agregando valor a las redes y comunidades, a través de las Rondas de Negocios con Impacto.
                    </p>
                </Row>
            </div>
            <div className="margen-superior">
                <Row className="text-center">
                    <Col>
                        <h1>7 años de historia</h1>
                    </Col>
                    <Col>
                    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="currentColor" class="bi bi-person-workspace" viewBox="0 0 16 16">
                    <path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H4Zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"/>
                    <path d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.373 5.373 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2H2Z"/>
                    </svg>
                        <p>39 Rondas de Negocios realizadas</p>
                    </Col>
                    <Col>
                    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="currentColor" class="bi bi-people-fill" viewBox="0 0 16 16">
                    <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"/>
                    </svg>
                        <p>Comunidad de +14.000 organizaciones</p>
                    </Col>
                    
                    <Col>
                    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="currentColor" class="bi bi-currency-exchange" viewBox="0 0 16 16">
                    <path d="M0 5a5.002 5.002 0 0 0 4.027 4.905 6.46 6.46 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05c0-.046 0-.093.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.46 3.46 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98c-.003.046-.003.097-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5zm16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0zm-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787H8.25zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674l.077.018z"/>
                    </svg>
                        <p>+150 MM de USD en proyecciones de negocios</p>
                    </Col>

                    <Col>
                    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="currentColor" class="bi bi-graph-up-arrow" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M0 0h1v15h15v1H0V0Zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5Z"/>
                    </svg>
                        <p>+19.000 Reuniones realizadas</p>
                    </Col>
                </Row>
            </div>
            <div className='division'>
                <br></br>
            </div>
            <div id='que_hacemos'>
                <Container fluid="md">
                    <Row>
                        <Row className="text-center">
                            <h2 className="mt-4 mb-4">¿Que Hacemos?</h2>
                        </Row>
                    </Row>
                </Container>
            </div>
            <div className='division'>
                <br></br>
            </div>
            <div className="margen-superior">
                <Row className="justify-content-center">
                    <Col>
                    <Card className="custom-card-bg h-100">
                        <Card.Body style={{backgroundColor:"#4E4E7A"}}>
                            <Card.Title className='card_title'>Rondas de Negocios</Card.Title>
                            <Card.Text className='card_text'>
                            Plataforma B2B para reuniones virtuales entre emprendimiento, pymes, Empresas B, grandes empresas y organizaciones publica, y privadas de diversos rubros y tematicas.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    </Col>
                    <Col>
                    <Card className="custom-card-bg h-100">
                        <Card.Body style={{backgroundColor:"#A3C7CA"}}>
                            <Card.Title className='card_title'>Aceleración de alianzas</Card.Title>
                            <Card.Text className='card_text'>
                            Buscamos generar oportunidades comerciales, alianzas estratégica y redes.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    </Col>
                    <Col>
                    <Card className="custom-card-bg h-100">
                        <Card.Body style={{backgroundColor:"#1CB5BA"}}>
                            <Card.Title className='card_title'>Enfocado en Triple Impacto</Card.Title>
                            <Card.Text className='card_text'>
                            Promovemos especialmente las vinculaciones de Triple Impacto: Económico, Social y Ambiental. 
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    </Col>
                    <Col>
                    <Card className="custom-card-bg h-100">
                        <Card.Body style={{backgroundColor:"#9C8DB2"}}>
                            <Card.Title className='card_title'>Generación de contenido</Card.Title>
                            <Card.Text className='card_text'>
                            Facilitamos contenidos innovador reflexivo e inspirador. incorporando actores influyentes del mundo publico, privado y civil.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    </Col>
                </Row>
            </div>
            <div className='division'>
                <br></br>
            </div>
            <div id='contactanos'>
                <Container fluid="md">
                    <Row>
                        <Row className="text-center">
                            <h2 className="mt-4 mb-4">Contactanos</h2>
                        </Row>
                    </Row>
                </Container>
            </div>
            <div className='division'>
                <br></br>
            </div>
            <div className='division'>
            <Row className="justify-content-md-center">
            <Col  md="10" style={{marginTop:"25px", marginBottom:"25px", backdropFilter: "blur(3px)",boxSizing:"border-box",boxShadow: "0 15px 25px rgba(0, 0, 0, .6)",borderRadius: "10px"}}>
                <Form>
                    <Row className="justify-content-md-center">
                        <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label style={{color:"white",fontSize:"20px"}}>Nombre</Form.Label>
                        <Form.Control placeholder="Ingrese nombre" />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label style={{color:"white",fontSize:"20px"}}>Apellido</Form.Label>
                        <Form.Control placeholder="Ingrese apellido" />
                        </Form.Group>
                    </Row>

                    <Form.Group className="mb-3" controlId="formGridAddress1">
                        <Form.Label style={{color:"white",fontSize:"20px"}}>Email</Form.Label>
                        <Form.Control type="email" placeholder="Ingrese email" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formGridAddress2">
                        <Form.Label style={{color:"white",fontSize:"20px"}}>Telefono</Form.Label>
                        <Form.Control placeholder="+56 9 9999 9999" />
                    </Form.Group>

                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="formGridCity">
                        <Form.Label style={{color:"white",fontSize:"20px"}}>Region</Form.Label>
                        <Form.Control />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridZip">
                        <Form.Label style={{color:"white",fontSize:"20px"}}>Mensaje</Form.Label>
                        <Form.Control />
                        </Form.Group>
                    </Row>

                    <div className="d-flex justify-content-center align-items-center">
                        <Button style={{backgroundColor:"white",color:"black",marginBottom:"10px"}} type="submit">
                            Enviar
                        </Button>
                    </div>
                </Form>
                </Col>
            </Row>
            </div>

        </>
    );
};

export {Home};