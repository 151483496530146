import React from 'react';
import "../css/Navpanel.css";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../images/negocio_con_impacto.png';

function Navpanel() {
    return(
        <Navbar expand="lg" sticky="top" data-bs-theme="dark" className="custom-navbar">
            <Container fluid>
            <Navbar.Brand href="/" >
                <img src={logo} alt="" style={{ maxWidth: '70%', maxHeight: '50%',marginRight: '-120px',marginLeft: '50px' }}/>
                </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
                <Nav className="ms-auto my-2 my-lg-0" style={{ maxHeight: '100px' }} navbarScroll>
                <Nav.Link className="custom-link" href="/#quienes_somos">¿Quienes Somos?</Nav.Link>
                <Nav.Link className="custom-link" href="/#que_hacemos">¿Que Hacemos?</Nav.Link>
                <Nav.Link className="custom-link" href="/Inscripcion">Organiza con Nosotros</Nav.Link>
                <Nav.Link className="custom-link" href="/#contactanos">¡Contactanos!</Nav.Link>
                <Nav.Link className="login-button" href="/Iniciar_Sesion">Iniciar sesión</Nav.Link>
                </Nav>
            </Navbar.Collapse>
            </Container>
        </Navbar>
    );

};

export {Navpanel}